import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ListGroup, ListGroupItem } from 'reactstrap';
import CouponRow from '../components/CouponRow';
import LoadingText from '../components/LoadingText';
import { apply } from '../store/slices/coupons';
import { discount } from '../utility/calculations';
import http from '../utility/http';

const EndMessage = () => (
    <p className="text-center text-muted my-3">
        <small>No more coupons to show.</small>
    </p>
);

const Loader = () => (
    <p className="text-center my-3">
        <LoadingText />
    </p>
);

class CouponListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            more: false,
            page: 0,
        }
    }

    componentDidMount() {
        this.loadNext()
    }

    loadNext() {
        const { page } = this.state;
        const { store } = this.props;
        this.setState({ loading: true });
        return http.get('coupons', { params: { store: store.slug, page: page + 1 } })
            .then(({ data: { data, meta } }) => {
                this.setState(state => ({
                    data: state.data.concat(data),
                    loading: false,
                    more: meta.current_page < meta.last_page,
                    page: meta.current_page,
                }))
            })
            .catch(() => this.setState({
                loading: false,
            }))
    }

    render() {
        const { apply, cart, history, store } = this.props;
        const { data, loading, more } = this.state;
        return (
            <div>
                <Helmet>
                    <title>Coupons | {store.name}</title>
                </Helmet>
                {data.length === 0 && loading ? (
                    <Loader />
                ) : (
                    <InfiniteScroll
                        dataLength={data.length}
                        endMessage={<EndMessage />}
                        hasMore={more}
                        loader={<Loader />}
                        next={() => this.loadNext()}>
                        <ListGroup flush>
                            {data.map(coupon => {
                                const off = discount(cart, coupon);
                                if (off > 0) {
                                    return (
                                        <ListGroupItem action
                                                       href=""
                                                       key={`coupon-${coupon.id}`}
                                                       onClick={event => {
                                                           event.preventDefault();
                                                           apply({ coupon });
                                                           history.push(`/${store.slug}/cart`)
                                                       }}>
                                            <CouponRow applicable={true} coupon={coupon} />
                                        </ListGroupItem>
                                    )
                                }

                                return (
                                    <ListGroupItem disabled key={`coupon-${coupon.id}`}>
                                        <CouponRow applicable={false} coupon={coupon} />
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </InfiniteScroll>
                )}
            </div>
        )
    }
}

export default connect(state => ({
    cart: state.cart.data,
    store: state.store.data,
}), {
    apply,
})(withRouter(CouponListView));
