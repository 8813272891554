import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';

const BannerSlider = ({ banners }) => {
    const [animating, setAnimating] = useState(false);
    const [index, setIndex] = useState(0);
    const goto = i => {
        if (!animating) {
            setIndex(i)
        }
    }
    const next = () => {
        if (!animating) {
            const i = index === items.length - 1 ? 0 : index + 1;
            setIndex(i)
        }
    }
    const previous = () => {
        if (!animating) {
            const i = index === 0 ? items.length - 1 : index - 1;
            setIndex(i)
        }
    }
    const items = banners.map((banner, i) => {
        return (
            <CarouselItem
                key={`banner-${banner.id}`}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}>
                <div className="px-3">
                    <img alt={`Banner ${i}`} className="rounded d-block w-100" src={banner.image}/>
                </div>
            </CarouselItem>
        )
    });
    return (
        <Carousel
            activeIndex={index}
            interval={5 * 1000}
            next={next}
            previous={previous}
            ride="carousel">
            {items.length > 1 ? <CarouselIndicators activeIndex={index} items={items} onClickHandler={goto} /> : null}
            {items}
        </Carousel>
    );
};

BannerSlider.propTypes = {
    banners: PropTypes.array.isRequired,
};

export default BannerSlider;
