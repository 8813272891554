import React, { Component } from 'react';
import { connect } from 'react-redux';
import { identify } from '../store/slices/cart';
import { fetch as fetchHotspot } from '../store/slices/hotspot';
import { fetch as fetchStore } from '../store/slices/store';
import LoadingErrorText from '../components/LoadingErrorText';
import LoadingText from '../components/LoadingText';
import StoreView from './StoreView';

class MainView extends Component {

    componentDidMount() {
        this.refreshStore()
    }

    refreshStore() {
        const { fetchHotspot, fetchStore, identify } = this.props;
        const slug = window.location.pathname.split('/')[1];
        const params = new URLSearchParams(window.location.search);
        const hotspot = params.get('hotspot') || null;
        const cart = params.get('cart') || null;
        fetchStore(slug);
        if (hotspot) {
            fetchHotspot(slug, hotspot)
        }

        if (cart) {
            identify(cart);
        }
    }

    render() {
        const handleRefresh = e => {
            e.preventDefault();
            this.refreshStore()
        };
        const { store, error } = this.props;
        if (error) {
            return (
                <p className="text-center m-3">
                    <LoadingErrorText onRetry={handleRefresh}>
                        There was an error in loading store information.
                    </LoadingErrorText>
                </p>
            )
        }

        if (store != null) {
            return (
                <>
                    <StoreView />
                    {store.custom_script && <div dangerouslySetInnerHTML={{ __html: store.custom_script }}></div>}
                </>
            )
        }

        return <p className="text-center m-3"><LoadingText /></p>
    }
}

export default connect(state => ({
    store: state.store.data,
    error: state.store.error,
}), {
    fetchHotspot,
    fetchStore,
    identify,
})(MainView)
