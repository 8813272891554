import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle, faArrowRight, faPhoneAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ItemRowHistory from "../components/ItemRowHistory";
import LoadingErrorText from '../components/LoadingErrorText';
import LoadingText from '../components/LoadingText';
import LocalTime from '../components/LocalTime';
import { show as toast } from '../store/slices/toast';
import { orderStatusColor, orderStatusIcon, orderStatusName } from '../utility/fixtures';
import http from '../utility/http';

class OrderView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: null,
            error: true,
            payment: null,
            loading: true,
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const payment = params.get('payment') || null;
        if (!!payment) {
            this.setState({ payment });
            const { toast } = this.props;
            if (payment === 'failure') {
                toast({
                    color: 'danger',
                    heading: 'Error!',
                    message: 'There was an issue with your payment. Please try again.',
                });
            } else if (payment === 'success') {
                toast({
                    color: 'success',
                    heading: 'Thanks!',
                    message: 'Your order was placed successfully.',
                });
            }
        }
        this.refreshOrder()
    }

    refreshOrder() {
        this.setState({
            error: false,
            loading: true,
        });
        const order = this.props.match.params.id;
        const { auth, store: { slug } } = this.props;
        http.get(`orders/${order}`, {
            headers: {
                'Authorization': `Bearer ${auth.token}`,
            },
            params: { store: slug },
        }).then(({ data: { data } }) => {
            this.setState({
                order: data,
                loading: false,
            })
        }).catch(() => {
            this.setState({
                error: true,
                loading: false,
            })
        })
    }

    render() {
        const { store } = this.props;
        const { error, loading, order } = this.state;
        const handleRefresh = e => {
            e.preventDefault();
            this.refreshOrder()
        };
        return (
            <div>
                <Helmet>
                    <title>{order ? '#' + order.uid : 'Loading...'} | Orders | {store.name}</title>
                </Helmet>
                {loading ? (
                    <p className="text-center my-3 my-lg-5"><LoadingText /></p>
                ) : error ? (
                    <p className="text-center my-3 my-lg-5">
                        <LoadingErrorText onRetry={handleRefresh}>
                            There was an error in loading order information.
                        </LoadingErrorText>
                    </p>
                ) : (
                    <div>
                        <div className="bg-white border-bottom p-3 mb-3">
                            <div className="d-flex justify-content-between">
                                <strong>Reference</strong>
                                <span>#{order.uid}</span>
                            </div>
                        </div>
                        <ListGroup className="border-top border-bottom mb-3" flush>
                            {order.items.map(orderItem => (
                                <ListGroupItem key={`item-${orderItem.id}`}>
                                    <ItemRowHistory orderItem={orderItem} />
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                        {order.coupon ? (
                            <div className="bg-white border-top border-bottom p-3 mb-3">
                                <div className="d-flex justify-content-between">
                                    <strong>Coupon applied</strong>
                                    <span>{order.coupon}</span>
                                </div>
                            </div>
                        ) : null}
                        {order.address ? (
                            <div className="bg-white border-top border-bottom p-3 mb-3">
                                <p className="mb-1"><strong>Delivery address</strong></p>
                                <p className="mb-0">
                                    {order.address.split('\n').map((item, key) => {
                                        return <Fragment key={key}>{item}<br/></Fragment>
                                    })}
                                </p>
                                {order.postal_code && <p className="mb-0">{order.postal_code}</p>}
                            </div>
                        ) : null}
                        <div className="bg-white border-top border-bottom p-3 mb-3">
                            {order.coupon || order.delivery_fee ? (
                                <div className="d-flex justify-content-between">
                                    <strong>Subtotal</strong>
                                    <span>{order.subtotal_display}</span>
                                </div>
                            ) : null}
                            {order.coupon ? (
                                <div className="d-flex justify-content-between">
                                    <strong>Discount</strong>
                                    <span>{order.discount_display}</span>
                                </div>
                            ) : null}
                            {order.delivery_fee ? (
                                <div className="d-flex justify-content-between">
                                    <strong>Delivery fee</strong>
                                    <span>{order.delivery_fee_display}</span>
                                </div>
                            ) : null}
                            <div className="d-flex justify-content-between">
                                <strong>Total</strong>
                                <span>{order.total_display}</span>
                            </div>
                        </div>
                        <div className="bg-white border-top border-bottom p-3 mb-3">
                            <div className="d-flex justify-content-between">
                                <strong>Payment</strong>
                                <span className={`text-${order.payment ? 'success' : 'warning'}`}>
                                    <FontAwesomeIcon className="mr-1"
                                                     icon={order.payment ? faCheckCircle : faExclamationTriangle} />
                                            {' '}
                                            {order.payment ? order.payment_display : 'Pending'}
                                </span>
                            </div>
                        </div>
                        {order.payment ? (
                            <div className="bg-white border-top border-bottom p-3 mb-3">
                                <div className="d-flex justify-content-between">
                                    <strong>Status</strong>
                                    <span className={`text-${orderStatusColor(order.status)}`}>
                                        <FontAwesomeIcon className="mr-1"
                                                         icon={orderStatusIcon(order.status, order.type)} />
                                                {' '}
                                                {orderStatusName(order.status)}
                                    </span>
                                </div>
                                {order.delivery_partner && (
                                    <div className="d-flex justify-content-between">
                                        <strong>Delivery partner</strong>
                                        <span>
                                            <span>{order.delivery_partner.name}</span>
                                            <Button className="ml-1 px-1 py-0" color="light"
                                                    href={'tel:' + order.delivery_partner.phone} size="sm" tag="a"
                                                    target="_blank">
                                                <FontAwesomeIcon fixedWidth icon={faPhoneAlt}/>
                                            </Button>
                                        </span>
                                    </div>
                                )}
                                {order.tracking_link && (
                                    <div className="d-flex justify-content-between">
                                        <strong>Tracking link</strong>
                                        <span>
                                            <a className="text-body" href={order.tracking_link} rel="noopener noreferrer" target="_blank">{order.tracking_link.substring(0, 16)}&hellip;</a>
                                            <Button className="ml-1 px-1 py-0" color="light"
                                                    href={order.tracking_link} rel="noopener noreferrer" size="sm"
                                                    tag="a" target="_blank">
                                                <FontAwesomeIcon fixedWidth icon={faExternalLinkAlt}/>
                                            </Button>
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : null}
                        {order.payment ? (
                            <div className="text-center mx-3 mb-3">
                                <small><strong>Ordered on</strong> <LocalTime value={order.created_at}/></small>
                            </div>
                        ) : (
                            <div>
                                <div className="d-flex">
                                    <span className="m-3">&nbsp;</span>
                                </div>
                                <div className="bg-white border-top position-fixed shadow"
                                     style={{bottom: 0, left: 0, right: 0, zIndex: 1}}>
                                    <div className="d-flex justify-content-between">
                                        <span className="m-3">{order.total_display}</span>
                                        <Button className="rounded-0"
                                                color="success"
                                                disabled={!store.serviceable}
                                                onClick={() => window.location.href = order.payment_link}
                                                rel="noopener noreferrer">
                                            Make payment <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default connect(state => ({
    auth: state.auth,
    store: state.store.data,
}), { toast })(withRouter(OrderView));
