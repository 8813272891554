import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const LocalTime = props => {
    const m = moment(props.value);
    return <time dateTime={m.format('YYYY-MM-DD HH:mm')}>{m.format('DD/MM/YYYY HH:mm:ss')}</time>
};

LocalTime.propTypes = {
    value: PropTypes.string.isRequired,
};

export default LocalTime;
