import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DebounceInput from 'react-debounce-input';
import { withRouter } from 'react-router-dom';

class SearchBox extends Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
    }

    componentDidMount() {
        const { location: { search } } = this.props;
        if (search.includes('autofocus=1')) {
            this.$input.focus();
        }
    }

    render() {
        const { onChange } = this.props;
        return (
            <DebounceInput className="form-control form-control-lg"
                           debounceTimeout={500}
                           inputRef={ref => this.$input = ref}
                           minLength={2}
                           onChange={event => onChange(event.target.value)}
                           placeholder="Search for items&hellip;" />
        )
    }
}

export default withRouter(SearchBox)
