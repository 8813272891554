import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardText } from 'reactstrap';
import PropTypes from 'prop-types';
import ImageOrPlaceholder from './ImageOrPlaceholder';

const CategoryCell = ({ store, category, ...props }) => (
    <Card {...props}>
        <CardBody className="p-2">
            <div className="d-flex justify-content-center mb-3">
                <Link to={`/${store.slug}/categories/${category.id}/items`}>
                    <ImageOrPlaceholder alt={category.name} size={100} src={category.image} />
                </Link>
            </div>
            <CardText className="text-center">
                <Link className="text-body" to={`/${store.slug}/categories/${category.id}/items`}>{category.name}</Link>
            </CardText>
        </CardBody>
    </Card>
);

CategoryCell.propTypes = {
    category: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default CategoryCell;
