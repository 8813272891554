import { createSlice } from '@reduxjs/toolkit';
import store from 'store';
import {
    getItemQuantityFromCartWithoutOptions,
    getItemQuantityFromCartWithOptions,
    updateItemQuantityInCartWithoutOptions,
    updateItemQuantityInCartWithOptions,
} from '../../utility/cart';

export const match = (data, item, options) => {
    let same = false;
    if (data.item.id === item.id) {
        if (options) {
            if (data.options && data.options.length === options.length) {
                let matched = true;
                options.forEach(y => {
                    const existing = data.options.find(z => z.option.id === y.option.id);
                    matched = existing && existing.value.id === y.value.id && matched
                })
                if (matched) {
                    same = true
                }
            }
        } else {
            same = true
        }
    }

    return same
};

export const slice = createSlice({
    name: 'cart',
    initialState: {
        data: [],
        initialized: false,
        uid: store.get('cart'),
    },
    reducers: {
        assign(state, { payload: { uid, items } }) {
            state.uid = uid;
            state.data = items;
            state.initialized = true;
            store.set('cart', uid);
        },
        clear(state) {
            state.data = [];
        },
        decrement(state, { payload: { item, options } }) {
            if (item.options?.length) {
                const existing = getItemQuantityFromCartWithOptions({ items: state.data }, item, options);
                if (existing > 0) {
                    const cart = updateItemQuantityInCartWithOptions({ items: state.data }, item, options, -1);
                    state.data = cart.items;
                }
            } else {
                const existing = getItemQuantityFromCartWithoutOptions({ items: state.data }, item);
                if (existing > 0) {
                    const cart = updateItemQuantityInCartWithoutOptions({ items: state.data }, item, -1);
                    state.data = cart.items;
                }
            }
        },
        identify(state, { payload }) {
            state.uid = payload;
            store.set('cart', payload);
        },
        increment(state, { payload: { item, options } }) {
            if (item.options?.length) {
                const existing = getItemQuantityFromCartWithOptions({ items: state.data }, item, options);
                if (existing > 0) {
                    const cart = updateItemQuantityInCartWithOptions({ items: state.data }, item, options, 1);
                    state.data = cart.items;
                } else {
                    state.data.push({ item, quantity: 1, options });
                }
            } else {
                const existing = getItemQuantityFromCartWithoutOptions({ items: state.data }, item);
                if (existing > 0) {
                    const cart = updateItemQuantityInCartWithoutOptions({ items: state.data }, item, 1);
                    state.data = cart.items;
                } else {
                    state.data.push({ item, quantity: 1 });
                }
            }
        },
    },
});

export const { assign, clear, decrement, identify, increment } = slice.actions;

export default slice.reducer;
