import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { hide } from '../store/slices/toast';

class ToastMessage extends Component {

    static propTypes = {
        toast: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
        const { hide, toast } = this.props;
        this.timeout = setTimeout(
            () => hide(toast.id),
            (toast.timeout || 5) * 1000
        )
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    render() {
        const { hide, toast } = this.props;
        return (
            <Toast className="w-100 mw-100">
                <ToastHeader className={`text-${toast.color || 'body'}`} toggle={() => hide(toast.id)}>
                    {toast.heading || 'Attention!'}
                </ToastHeader>
                <ToastBody>{toast.message}</ToastBody>
            </Toast>
        )
    }
}

export default connect(null, { hide })(ToastMessage)
