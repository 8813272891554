import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import CartView from './CartView';
import CategoryListView from '../views/CategoryListView';
import CouponListView from './CouponListView';
import ItemDetailView from './ItemDetailView';
import ItemListView from './ItemListView';
import HomeView from './HomeView';
import OrderListView from '../views/OrderListView';
import OrderView from '../views/OrderView';
import LoginModal from '../components/LoginModal';
import FloatingActions from '../components/FloatingActions';
import StoreHeader from '../components/StoreHeader';
import Toasts from '../components/Toasts';
import useDebounce from '../hooks/useDebounce';
import { logout, popup as login } from '../store/slices/auth';
import { assign } from '../store/slices/cart';
import { fetch } from '../store/slices/store';
import http from '../utility/http';
import createManifest from '../utility/manifest';

function StoreView({ assign, auth, cart, logout, store }) {
    useEffect(() => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'manifest');
        link.setAttribute('href', createManifest(store));
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [store]);

    useEffect(() => {
        if (!!auth.token) {
            http.get('profile', {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                }
            }).then(({ data: { data: { name, phone } } }) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(`Logged in as "${name}" or ${phone}.`)
                }
            }).catch(() => {
                if (process.env.NODE_ENV === 'development') {
                    console.warn('Token does not seem to be valid.')
                }
                logout()
            })
        }
    }, [auth, logout]);

    useEffect(() => {
        const params = {
            store: store.slug,
            uid: cart.uid || null,
        };
        http.get('cart', {
            headers: auth.token && {
                'Authorization':`Bearer ${auth.token}`,
            },
            params
        }).then(({ data }) => {
            assign(data.data);
        }).catch(() => {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Could not get cart data from server.')
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const update = useDebounce(() => {
        if (!cart.initialized) {
            return;
        }

        const data = {
            store: store.slug,
            uid: cart.uid,
            items: cart.data,
        };
        http.put('cart', data, {
            headers: auth.token && {
                'Authorization':`Bearer ${auth.token}`,
            }
        }).catch(() => {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Could not update cart data on server.')
            }
        });
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => update(), [cart]);

    return (
        <>
            <Helmet>
                <title>{store.name}</title>
            </Helmet>
            <Router>
                <StoreHeader store={store}/>
                <Container className="px-0">
                    <Row className="justify-content-center no-gutters">
                        <Col sm={10} md={8} lg={6}>
                            <Switch>
                                <Route path={`/${store.slug}/cart`}>
                                    <CartView />
                                    <FloatingActions />
                                </Route>
                                <Route exact path={`/${store.slug}/categories/:id/items`}>
                                    <ItemListView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}/categories/:category/items/:item`}>
                                    <ItemDetailView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}/categories`}>
                                    <CategoryListView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}/coupons`}>
                                    <CouponListView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}/orders/:id`}>
                                    <OrderView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}/orders`}>
                                    <OrderListView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}/search`}>
                                    <ItemListView />
                                    <FloatingActions />
                                </Route>
                                <Route path={`/${store.slug}`}>
                                    <HomeView />
                                    <FloatingActions />
                                </Route>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </Router>
            <Toasts/>
            <LoginModal store={store}/>
        </>
    );
}

export default connect(state => ({
    auth: state.auth,
    cart: state.cart,
    store: state.store.data,
}), {assign, login, logout, fetch})(StoreView)
