/**
 * @param {{items: Array<{item: {id: number}}>}} cart
 * @param {{id: number}} item
 * @return {number}
 */
export function getItemQuantityFromCart(cart, item) {
    return cart.items
        .filter((x) => x.item.id === item.id)
        .reduce((sum, x) => sum + x.quantity, 0);
}

/**
 * @param {{items: Array<{item: {id: number}, options: Array<{option: {id: number}, value: {id: number}}>}>}} cart
 * @param {{id: String|number}} item
 * @param {Array<{option: {id: number}, value: {id: number}}>} options
 * @return {number}
 */
export function getItemQuantityFromCartWithOptions(cart, item, options) {
    const cartItem = cart.items
        .filter((x) => x.item.id === item.id && !! x.options?.length)
        .find((x) => {
            for (const y of options) {
                const existing = x.options.find((z) => {
                    return z.option.id === y.option.id && z.value.id === y.value.id
                });
                if (! existing) {
                    return false;
                }
            }

            return x.options.length === options.length;
        });

    return cartItem?.quantity || 0;
}

/**
 * @param {{items: Array<{item: {id: number}}>}} cart
 * @param {{id: number}} item
 * @return {number}
 */
export function getItemQuantityFromCartWithoutOptions(cart, item) {
    return cart.items
        .filter((x) => x.item.id === item.id && ! x.options?.length)
        .reduce((sum, x) => sum + x.quantity, 0);
}

/**
 * @param {{inventories: Array<{options: Array<{id: String|number, value: String|number}>, quantity: number}>}} item
 * @param {Array<{option: {id: number}, value: {id: number}}>} options
 * @return {number}
 */
export function getItemQuantityFromInventoryWithOptions(item, options) {
    if (! item.inventory) {
        return 99;
    }

    if (! options?.length) {
        return item.inventories.find((x) => ! x.options?.length)?.quantity || 0;
    }

    const inventory = item.inventories.find((x) => {
        for (const y of options) {
            // eslint-disable-next-line
            const existing = x.options.find((z) => y.option.id == z.id && y.value.id == z.value);
            if (! existing) {
                return false;
            }
        }

        return x.options.length === options.length;
    });

    return inventory?.quantity || 0;
}

/**
 * @param {{inventories: Array<{quantity: number}>}} item
 * @return boolean
 */
export function isItemInStock(item) {
    return ! item.inventory || !! item.inventories.find((x) => x.quantity > 0);
}

/**
 * @param {{items: Array<{item: {id: number}, options: Array<{option: {id: number}, value: {id: number}}>}>}} cart
 * @param {{id: String|number}} item
 * @param {Array<{option: {id: number}, value: {id: number}}>} options
 * @param {number} quantity
 * @return {Object}
 */
export function updateItemQuantityInCartWithOptions(cart, item, options, quantity) {
    const items = cart.items
        .map((x) => {
            if (x.item.id !== item.id || ! x.options?.length) {
                return x;
            }

            for (const y of options) {
                const existing = x.options.find((z) => {
                    return z.option.id === y.option.id && z.value.id === y.value.id
                });
                if (! existing) {
                    return x;
                }
            }

            if (x.options.length === options.length) {
                x.quantity += quantity;
            }

            return x;
        })
        .filter((x) => x.quantity > 0);

    return { ...cart, items }
}

/**
 * @param {{items: Array<{item: {id: number}}>}} cart
 * @param {{id: String|number}} item
 * @param {number} quantity
 * @return {Object}
 */
export function updateItemQuantityInCartWithoutOptions(cart, item, quantity) {
    const items = cart.items
        .map((x) => {
            if (x.item.id !== item.id || x.options?.length) {
                return x;
            }

            return { ...x, quantity: x.quantity + quantity };
        })
        .filter((x) => x.quantity > 0);

    return { ...cart, items }
}
