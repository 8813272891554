import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export default function LoadingText() {
    return (
        <span>
            <FontAwesomeIcon className="text-muted" icon={faCircleNotch} spin /> Loading&hellip;
        </span>
    )
};
