export const discount = (cart, coupon) => {
    const total = subtotal(cart);
    let applicable = true;
    let discount = 0;
    if (coupon.minimum_total > 0) {
        applicable = coupon.minimum_total <= total;
    }

    let discountable = total;
    if (applicable && coupon.category) {
        discountable = 0;
        let matching = cart.items.filter(x => x.item.category.id === coupon.category.id);
        if (matching.length > 0) {
            matching.forEach(x => discountable += Number(x.price) * x.quantity)
        }
    }

    if (applicable && discountable > 0) {
        if (coupon.mode === 'fixed') {
            discount = coupon.discount
        } else {
            discount = ((discountable * coupon.discount) / 100).toFixed(2)
        }
    }

    discount = discount > 0 ? discount : 0;
    if (coupon.maximum_discount > 0 && discount > coupon.maximum_discount) {
        discount = coupon.maximum_discount * 1
    }

    return discount
};

export const subtotal = cart => {
    let amount = 0;
    cart.forEach(({ item, options, quantity }) => {
        let cost = Number(item.price);
        if (options) {
            options.forEach(x => cost += Number(x.value.price))
        }

        amount += cost * quantity
    });
    return amount
};

export const total = (cart, coupon, store, service) => {
    let amount = subtotal(cart);
    if (coupon) {
        amount = amount - discount(cart, coupon)
    }

    if (service === 'delivery' && store.delivery_fee > 0) {
        amount += Number(store.delivery_fee)
    }

    return amount
};
