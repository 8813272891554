import React from 'react';
import PropTypes from 'prop-types';

const CouponRow = ({ applicable, coupon }) => (
    <div>
        <h5 className="mb-1">{coupon.code}</h5>
        <p className={applicable ? 'mb-0' : 'mb-1'}>{coupon.description || 'No description available.'}</p>
        {!applicable ? (
            <p className="text-danger text-right mb-0">
                <small>Not applicable.</small>
            </p>
        ) : null}
    </div>
);

CouponRow.propTypes = {
    applicable: PropTypes.bool.isRequired,
    coupon: PropTypes.object.isRequired,
};

export default CouponRow;
