import React, { useEffect, useState } from 'react';
import { CustomInput, Button } from 'reactstrap';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getItemQuantityFromCartWithOptions, getItemQuantityFromInventoryWithOptions } from '../utility/cart';

const ItemOptionValue = ({ value }) => {
    if (value.price > 0) {
        return (
            <span>
                <span>{value.name}</span> <span className="text-muted">+{value.price_display}</span>
            </span>
        )
    }

    return value.name
};

ItemOptionValue.propTypes = {
    option: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
};

const ItemOption = ({ onSelect, option, selection }) => {
    return (
        <div className="border-bottom p-3">
            <h5 className="text-secondary">{option.name}</h5>
            <div>
                {option.values.map(value => (
                    <CustomInput defaultChecked={selection && selection.value && selection.value.id === value.id}
                                 id={`option-${option.id}-value-${value.id}`}
                                 key={`option-${option.id}-value-${value.id}`}
                                 label={<ItemOptionValue option={option} value={value} />}
                                 onChange={event => event.currentTarget.checked && onSelect(value)}
                                 name={`option[${option.id}]`}
                                 type="radio" />
                ))}
            </div>
        </div>
    );
};

ItemOption.propTypes = {
    onSelect: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    selection: PropTypes.object,
};

const ItemOptionSheet = ({ cart, currency, item, onCommit, onDismiss, open }) => {
    const [selection, setSelection] = useState([]);
    const [total, setTotal] = useState(0);

    const handleSelection = (option, value) => {
        const updated = [ ...selection ];
        const existing = updated.find(x => x.option.id === option.id);
        if (existing) {
            existing.value = value;
        } else {
            updated.push({ option, value })
        }

        setSelection(updated)
    };

    useEffect(() => {
        if (item) {
            setSelection(item.options.map(option => ({
                option,
                value: option.values[0],
            })))
        } else {
            setSelection([])
        }
    }, [item]);

    useEffect(() => {
        if (item) {
            let amount = item.price * 1;
            selection.forEach(x => amount += x.value.price * 1);
            setTotal(amount);
        } else {
            setTotal(0);
        }
    }, [item, selection]);

    const cartQuantity = item ? getItemQuantityFromCartWithOptions({ items: cart }, item, selection) : 0;
    const stockQuantity = item ? getItemQuantityFromInventoryWithOptions(item, selection) : 0;
    const quantity = stockQuantity - cartQuantity;

    return (
        <SwipeableBottomSheet
            onChange={isOpen => !isOpen && onDismiss()}
            open={open}
            style={{ zIndex: 999 }}>
            <div>
                {item && item.options.map(option => (
                    <div key={`option-${option.id}`}>
                        <ItemOption fullSelection={selection}
                                    item={item}
                                    onSelect={value => handleSelection(option, value)}
                                    option={option}
                                    selection={selection.find(x => x.option.id === option.id)} />
                    </div>
                ))}
                <div className="p-3 d-flex justify-content-between">
                    <span className="align-self-center"><strong>Item total:</strong> {currency}{total}</span>
                    {quantity <= 0 ? (
                        <Button className="border-transparent" color="danger" disabled={quantity <= 0} outline>
                            Out of stock
                        </Button>
                    ) : (
                        <Button color="success" disabled={quantity <= 0} onClick={() => onCommit(selection)}>
                            <FontAwesomeIcon icon={faPlus} /> Add
                        </Button>
                    )}
                </div>
            </div>
        </SwipeableBottomSheet>
    )
};

ItemOptionSheet.propTypes = {
    cart: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    onCommit: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    item: PropTypes.object,
    open: PropTypes.bool,
};

export default ItemOptionSheet;
