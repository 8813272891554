import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'coupons',
    initialState: {
        coupon: null,
    },
    reducers: {
        apply(state, { payload: { coupon } }) {
            state.loading = false;
            state.coupon = coupon
        },
        remove(state) {
            state.loading = false;
            state.coupon = null
        },
    },
});

export const { apply, remove } = slice.actions;

export default slice.reducer;
