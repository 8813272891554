import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import ItemCell from '../components/ItemCell';
import ItemOptionSheet from '../components/ItemOptionSheet';
import LoadingText from '../components/LoadingText';
import SearchBox from '../components/SearchBox';
import { decrement, increment } from '../store/slices/cart';
import { getItemQuantityFromCart, getItemQuantityFromInventoryWithOptions, isItemInStock } from '../utility/cart';
import http from '../utility/http';

const EndMessage = () => (
    <p className="text-center text-muted mt-3 mb-0">
        <small>No more items to show here.</small>
    </p>
);

const Loader = () => (
    <p className="text-center mt-3 mb-0">
        <LoadingText />
    </p>
);

class ItemListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            more: false,
            options: {
                item: null,
                open: false,
            },
            page: 0,
            q: '',
        };
    }

    componentDidMount() {
        this.loadNext()
    }

    handleDecrement(item, options) {
        const { decrement } = this.props;
        decrement({ item, options });
        this.handleOptionsHide()
    }

    handleIncrement(item, options) {
        const { increment } = this.props;
        increment({ item, options });
        this.handleOptionsHide()
    }

    handleOptionsHide() {
        this.setState({
            options: {
                item: null,
                open: false,
            }
        })
    }

    handleOptionsShow(item) {
        this.setState({
            options: {
                item,
                open: true,
            }
        })
    }

    handleSearch(q) {
        this.setState({ data: [], more: false, page: 0, q }, this.loadNext)
    }

    loadNext() {
        const category = this.props.match.params.id;
        const { page, q } = this.state;
        const { store } = this.props;
        this.setState({ loading: true })
        return http.get('items', { params: { store: store.slug, category, q, page: page + 1 } })
            .then(({ data: { data, meta } }) => {
                this.setState(state => ({
                    data: state.data.concat(data),
                    loading: false,
                    more: meta.current_page < meta.last_page,
                    page: meta.current_page,
                }))
            })
            .catch(() => this.setState({
                loading: false,
            }))
    }

    render() {
        const { cart, store } = this.props;
        const { data, loading, more, options } = this.state;
        return (
            <div>
                <Helmet>
                    <title>Items | {store.name}</title>
                </Helmet>
                <div className="mx-3">
                    <div className="my-3">
                        <SearchBox onChange={q => this.handleSearch(q)} />
                    </div>
                    {data.length === 0 && loading ? (
                        <Loader />
                    ) : (
                        <InfiniteScroll
                            dataLength={data.length}
                            endMessage={<EndMessage />}
                            hasMore={more}
                            loader={<Loader />}
                            next={() => this.loadNext()}>
                            <div className="item-layout">
                                {data.map(item => (
                                    <div className="item-cell" key={`item-${item.id}`}>
                                        <ItemCell className="border-0 shadow-sm"
                                                  inStock={isItemInStock(item)}
                                                  item={item}
                                                  quantity={getItemQuantityFromCart({ items: cart }, item)}
                                                  quantityInStock={
                                                      item.options.length
                                                          ? undefined
                                                          : getItemQuantityFromInventoryWithOptions(item, [])
                                                  }
                                                  store={store}
                                                  onDecrement={() => this.handleDecrement(item)}
                                                  onIncrement={() => {
                                                      item.options.length
                                                          ? this.handleOptionsShow(item)
                                                          : this.handleIncrement(item)
                                                  }} />
                                    </div>
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
                <ItemOptionSheet cart={cart}
                                 currency={store.currency_symbol}
                                 onCommit={selection => this.handleIncrement(options.item, selection)}
                                 onDismiss={() => this.handleOptionsHide()}
                                 item={options.item}
                                 open={options.open} />
            </div>
        )
    }
}

export default connect(state => ({
    cart: state.cart.data,
    store: state.store.data,
}), {
    decrement,
    increment,
})(withRouter(ItemListView));
