import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import ImageOrPlaceholder from './ImageOrPlaceholder';

const ItemRowHistory = ({ orderItem: { item, options, quantity, total } }) => (
    <Media>
        <div className="mr-3">
            <ImageOrPlaceholder alt={item.name} size={48} src={item.image} />
        </div>
        <Media body>
            <p className="mb-1">{item.name}</p>
            {options ? (
                <p className="text-lowercase text-muted mb-1" style={{lineHeight: '1em'}}>
                    {options.map(x => (
                        <small className="mr-1" key={`item-${item.id}-option-${x.item_option.id}`}>
                            <strong>{x.item_option.name}:</strong> {x.item_option_value.name}
                        </small>
                    ))}
                </p>
            ) : null}
            <p className="mb-0">x{quantity} = <strong>{total}</strong></p>
        </Media>
    </Media>
)

ItemRowHistory.propTypes = {
    orderItem: PropTypes.object.isRequired,
};

export default ItemRowHistory;
