import { createSlice } from '@reduxjs/toolkit';
import http from '../../utility/http';

export const slice = createSlice({
    name: 'store',
    initialState: {
        data: null,
        error: false,
        loading: false,
    },
    reducers: {
        failure(state) {
            state.error = true;
            state.loading = false
        },
        loading(state) {
            state.error = false;
            state.loading = true
        },
        success(state, { payload: { data } }) {
            state.loading = false;
            state.data = data
        },
    },
});

export const { failure, loading, success } = slice.actions;

export const fetch = slug => dispatch => {
    dispatch(loading());
    if (!slug) {
        dispatch(failure());
        return;
    }

    http.get('store', { params: { store: slug } })
        .then(({ data: { data } }) => dispatch(success({ data })))
        .catch(() => dispatch(failure()))
};

export default slice.reducer;
