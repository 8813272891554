import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faShoppingCart, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { subtotal } from '../utility/calculations';
import { popup as login } from '../store/slices/auth';

const BadgeStyled = styled.header`
  background: ${({ colors }) => colors.secondary.bg};
  color: ${({ colors }) => colors.secondary.fg};
`;

const HeaderStyled = styled.header`
  background: ${({ colors }) => colors.primary.bg};
  color: ${({ colors }) => colors.primary.fg};
`;

const HeaderLinkStyled = styled.a`
  color: ${({ colors }) => colors.primary.fg};
  &:active, &:focus, &:hover {
    color: ${({ colors }) => transparentize(0.1, colors.primary.fg)};
    text-decoration: none;
  }
`;

const StoreHeader = ({ auth, count, login, store: { currency_symbol, name, logo, slug, serviceable, colors }, total }) => {
    const { pathname } = useLocation();
    const handleLoginClicked = (e) => {
        e.preventDefault();
        login(true);
    };
    return (
        <HeaderStyled className="sticky-top shadow p-3" colors={colors}>
            <div className="d-flex align-items-center">
                {logo ? <img alt={name} className="mr-3" src={logo} height={50} /> : null}
                <HeaderLinkStyled as={Link} className="mr-auto" colors={colors} to={`/${slug}`}>
                    <strong className="text-uppercase">{name}</strong>
                </HeaderLinkStyled>
                {!!auth.token ? (
                    <HeaderLinkStyled as={Link} colors={colors} to={`/${slug}/orders`}>
                        <FontAwesomeIcon icon={faHistory} style={{fontSize: '1.5em'}} />
                    </HeaderLinkStyled>
                ) : (
                    <HeaderLinkStyled colors={colors} href="" onClick={handleLoginClicked}>
                        <FontAwesomeIcon icon={faUserCircle} style={{fontSize: '1.5em'}} />
                    </HeaderLinkStyled>
                )}
                {serviceable && (
                    <HeaderLinkStyled as={Link} className="position-relative ml-3" colors={colors} to={`/${slug}/cart`}>
                        {!pathname.endsWith("/cart") && total > 0 ? (
                            <span className="mr-3">{currency_symbol}{total}</span>
                        ) : null}
                        {count > 0 ? (
                            <BadgeStyled as={Badge} className="position-absolute" colors={colors} style={{right: '-5px', top: '-5px'}}>
                                {count}
                            </BadgeStyled>
                        ) : null}
                        <FontAwesomeIcon icon={faShoppingCart} style={{fontSize: '1.5em'}} />
                    </HeaderLinkStyled>
                )}
            </div>
        </HeaderStyled>
    )
};

StoreHeader.propTypes = {
    auth: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default connect(({ auth, cart }) => {
    let count = 0;
    cart.data.forEach(x => count += x.quantity);
    const total = subtotal(cart.data);
    return {
        auth,
        count,
        total,
    };
}, {
    login,
})(StoreHeader);
