import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Button,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { login, popup } from '../store/slices/auth';
import http from '../utility/http';

class LoginModal extends Component {

    static propTypes = {
        login: PropTypes.func.isRequired,
        popup: PropTypes.func.isRequired,
        store: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            errors: {
                name: null,
                otp: null,
                phone: null,
            },
            name: '',
            otp: '',
            phone: '',
            sent: false,
            submitting: false,
        };
        this.$otp = createRef()
    }

    handleLoginClicked({ slug }) {
        this.setState({
            errors: {
                name: null,
                otp: null,
                phone: null,
            },
            submitting: true,
        });
        const { name, phone, otp } = this.state;
        http.post('login/verify', { name, phone, otp }, { params: { store: slug } })
            .then(({ data }) => {
                this.setState({
                    sent: false,
                    submitting: false,
                }, () => {
                    this.props.login(data);
                    this.hide();
                })
            })
            .catch(error => {
                this.setState(state => ({
                    submitting: false,
                }), () => {
                    if (error.response && error.response.status === 422) {
                        const { errors } = error.response.data;
                        this.setState({
                            errors: {
                                name: errors.name && errors.name[0],
                                phone: errors.phone && errors.phone[0],
                                otp: errors.otp && errors.otp[0],
                            },
                        })
                    }
                })
            })
    }

    handleOtpClicked({ slug }) {
        this.setState({
            errors: {
                name: null,
                otp: null,
                phone: null,
            },
            submitting: true,
        });
        const { phone } = this.state;
        http.post('login/otp', { phone }, { params: { store: slug } })
            .then(() => {
                this.setState({
                    sent: true,
                    submitting: false,
                }, () => this.$otp.current.focus())
            })
            .catch(error => {
                this.setState(state => ({
                    submitting: false,
                }), () => {
                    if (error.response && error.response.status === 422) {
                        const { errors } = error.response.data;
                        this.setState({
                            errors: { phone: errors.phone && errors.phone[0] },
                        })
                    }
                })
            })
    }

    hide() {
        this.props.popup(false);
    }

    show() {
        this.props.popup(true);
    }

    render() {
        const { errors, name, otp, phone, sent, submitting } = this.state;
        const { auth: { popup }, store } = this.props;
        const handleNameChanged = event => this.setState({ name: event.target.value });
        const handleOtpChanged = event => this.setState({ otp: event.target.value });
        const handlePhoneChanged = event => this.setState({ phone: event.target.value });
        const handleToggle = () => {
            if (popup) {
                this.hide();
            } else {
                this.show();
            }
        };
        return (
            <Modal centered isOpen={popup} toggle={handleToggle}>
                <ModalHeader toggle={handleToggle}>Login</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="login-name">Name <span className="text-danger">*</span></Label>
                        <Input id="login-name" invalid={!!errors.name} onChange={handleNameChanged} value={name} />
                        {!!errors.name ? <FormFeedback>{errors.name}</FormFeedback> : null}
                    </FormGroup>
                    <FormGroup className={sent ? '' : 'mb-0'}>
                        <Label for="login-phone">Phone number <span className="text-danger">*</span></Label>
                        <Input disabled={sent}
                               id="login-phone"
                               invalid={!!errors.phone}
                               onChange={handlePhoneChanged}
                               type="tel"
                               value={phone} />
                        <FormText>Please enter your mobile number without {store.calling_code} prefix.</FormText>
                        {!!errors.phone ? <FormFeedback>{errors.phone}</FormFeedback> : null}
                    </FormGroup>
                    {sent ? (
                        <FormGroup className="mb-0">
                            <Label for="login-otp">OTP <span className="text-danger">*</span></Label>
                            <Input id="login-otp"
                                   innerRef={this.$otp}
                                   invalid={!!errors.otp}
                                   onChange={handleOtpChanged}
                                   type="number"
                                   value={otp} />
                            {!!errors.otp ? <FormFeedback>{errors.otp}</FormFeedback> : null}
                        </FormGroup>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                            disabled={submitting}
                            onClick={() => sent ? this.handleLoginClicked(store) : this.handleOtpClicked(store)}>
                        {submitting ? 'Please wait…' : sent ? 'Login' : 'Send OTP'}
                        {' '}
                        <FontAwesomeIcon className="ml-1"
                                         icon={submitting ? faCircleNotch : faArrowRight}
                                         spin={submitting} />
                    </Button>
                    <Button color="dark" onClick={handleToggle} outline>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default connect(state => ({ auth: state.auth }), { login, popup }, null, { forwardRef: true })(LoginModal)
