import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const LoadingErrorText = ({ children, onRetry }) => (
    <span>
        <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /> {children} <a href="/" onClick={onRetry}>Try again?</a>
    </span>
);

LoadingErrorText.propTypes = {
    onRetry: PropTypes.func.isRequired,
};

export default LoadingErrorText;
