import auth from './auth';
import cart from './cart';
import coupons from './coupons';
import hotspot from './hotspot';
import store from './store';
import toast from './toast';

const slices = {
    auth,
    cart,
    coupons,
    hotspot,
    store,
    toast,
};

export default slices
