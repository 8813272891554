import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import LoadingText from '../components/LoadingText';
import OrderRow from '../components/OrderRow';
import http from '../utility/http';

const EndMessage = () => (
    <p className="text-center text-muted my-3">
        <small>No more orders to show.</small>
    </p>
);

const Loader = () => (
    <p className="text-center my-3">
        <LoadingText />
    </p>
);

class OrderListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            more: false,
            page: 0,
        }
    }

    componentDidMount() {
        this.loadNext()
    }

    loadNext() {
        const { page } = this.state;
        const { auth, store } = this.props;
        this.setState({ loading: true })
        return http.get('orders', {
            headers: {
                'Authorization': `Bearer ${auth.token}`,
            },
            params: { store: store.slug, page: page + 1 }
        }).then(({ data: { data, meta } }) => {
            this.setState(state => ({
                data: state.data.concat(data),
                loading: false,
                more: meta.current_page < meta.last_page,
                page: meta.current_page,
            }))
        }).catch(() => this.setState({
            loading: false,
        }))
    }

    render() {
        const { store } = this.props;
        const { data, loading, more } = this.state;
        return (
            <div>
                <Helmet>
                    <title>Orders | {store.name}</title>
                </Helmet>
                {data.length === 0 && loading ? (
                    <Loader />
                ) : (
                    <InfiniteScroll
                        dataLength={data.length}
                        endMessage={<EndMessage />}
                        hasMore={more}
                        loader={<Loader />}
                        next={() => this.loadNext()}>
                        <ListGroup flush>
                            {data.map(order => (
                                <ListGroupItem action
                                               href=""
                                               key={`order-${order.id}`}
                                               tag={Link}
                                               to={`/${store.slug}/orders/${order.id}`}>
                                    <OrderRow order={order} />
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </InfiniteScroll>
                )}
            </div>
        )
    }
}

export default connect(state => ({
    auth: state.auth,
    store: state.store.data,
}))(OrderListView);
