export default function createManifest(store) {
    const manifest = {
        name: store.name,
        short_name: store.name,
        description: `${store.name} online store.`,
        start_url: store.link,
        display: "standalone",
        theme_color: store.colors.primary.bg,
        background_color: store.colors.primary.fg,
    };
    if (store.logo) {
        manifest.icons = [
            {
                src: store.logo,
                sizes: "512x512",
                type: "image/png",
            },
        ];
    }

    const str = JSON.stringify(manifest);
    const blob = new Blob([str], { type: "application/json" });
    return URL.createObjectURL(blob);
}
