import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Media } from 'reactstrap';
import ImageOrPlaceholder from './ImageOrPlaceholder';
import QuantityPlusMinus from './QuantityPlusMinus';

const ItemRow = ({ currency, item, inStock, onDecrement, onIncrement, options, quantity, quantityInStock, store }) => {
    let cost = item.price * 1;
    if (options) {
        options.forEach(x => cost += x.value.price * 1)
    }

    return (
        <Media>
            <div className="mr-3">
                <Link to={`/${store.slug}/categories/${item.category.id}/items/${item.id}`}>
                    <ImageOrPlaceholder alt={item.name} size={48} src={item.image} />
                </Link>
                <div className="w-100 d-flex justify-content-center">
                    {item.veg === 'veg' ? (
                        <div className="veg mt-1">
                            <div className="veg-box veg-box-v">
                                <div className="veg-dot veg-dot-v" />
                            </div>
                        </div>
                    ) : item.veg === 'non-veg' ? (
                        <div className="veg mt-1">
                            <div className="veg-box veg-box-nv">
                                <div className="veg-dot veg-dot-nv" />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <Media body>
                <p className="mb-1"><strong>{item.name}</strong></p>
                {options ? (
                    <p className="text-lowercase text-muted mb-1" style={{lineHeight: '1em'}}>
                        {options.map(x => (
                            <small className="mr-1" key={`item-${item.id}-option-${x.option.id}`}>
                                <strong>{x.option.name}:</strong> {x.value.name}
                            </small>
                        ))}
                    </p>
                ) : null}
                <p className="mb-1">{currency}{cost} x{quantity} = <strong>{currency}{cost * quantity}</strong></p>
                <div className={classNames('d-flex', 'justify-content-end', { 'mb-1': quantityInStock < quantity })}>
                    {quantity > 0 ? (
                        <QuantityPlusMinus max={quantityInStock}
                                           min={0}
                                           onIncrement={onIncrement}
                                           onDecrement={onDecrement}
                                           size="sm"
                                           value={quantity} />
                    ) : (
                        <Button onClick={onIncrement} outline size="sm">
                            <FontAwesomeIcon icon={faPlus} /> Buy
                        </Button>
                    )}
                </div>
                {quantityInStock < quantity && (
                    <p className="small text-center text-danger mb-0">
                        {quantityInStock > 0
                            ? `Only ${quantityInStock} quantity is available for this item.`
                            : 'This item is no longer in stock.'}
                    </p>
                )}
            </Media>
        </Media>
    )
}

ItemRow.propTypes = {
    currency: PropTypes.string.isRequired,
    inStock: PropTypes.bool,
    item: PropTypes.object.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    options: PropTypes.array,
    quantity: PropTypes.number.isRequired,
    quantityInStock: PropTypes.number.isRequired,
    store: PropTypes.object.isRequired,
};

export default ItemRow;
