import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './store';
import MainView from './views/MainView';

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    })
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MainView />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
