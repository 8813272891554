import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, CustomInput, FormGroup, Input, FormFeedback, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import storage from 'store';
import ItemRow from '../components/ItemRow';
import { clear, decrement, increment } from '../store/slices/cart';
import { remove } from '../store/slices/coupons';
import { popup as login } from '../store/slices/auth';
import { show as toast } from '../store/slices/toast';
import { discount, subtotal, total } from '../utility/calculations';
import { getItemQuantityFromInventoryWithOptions } from '../utility/cart';
import http from '../utility/http';

const createItemKey = cartItem => {
    const values = ['item', cartItem.item.id];
    if (cartItem.options) {
        cartItem.options.forEach(x => {
            values.push('option');
            values.push(x.option.id);
            values.push('value');
            values.push(x.value.id)
        })
    }

    return values.join('-')
};

const STORE_KEY_ADDRESS = 'last_order_address';
const STORE_KEY_POSTAL_CODE = 'last_order_postal_code';

const CartView = ({ auth, cart, clear, coupon, decrement, history, hotspot, increment, login, store, toast, remove }) => {
    const services = hotspot ? hotspot.services : store.services;
    const [address, setAddress] = useState(storage.get(STORE_KEY_ADDRESS) || '');
    const [postalCode, setPostalCode] = useState(storage.get(STORE_KEY_POSTAL_CODE) || '');
    const [service, setService] = useState(Object.keys(services)[0]);
    const [paymentMethod, setPaymentMethod] = useState(Object.keys(store.payment_methods)[0]);
    const [errors, setErrors] = useState({ address: null, postal_code: null });
    const [ordering, setOrdering] = useState(false);
    const [orderAutomatically, setOrderAutomatically] = useState(false);
    const $refAddress = useRef(null);
    const $refPostalCode = useRef(null);
    const handlePlaceOrder = () => {
        setErrors({ address: null });
        if (!!auth.token) {
            setOrdering(true);
            http.post('orders', {
                store: store.slug,
                hotspot: hotspot ? hotspot.id : null,
                cart: cart?.uid || null,
                coupon: coupon ? coupon.code : null,
                type: service,
                address: address.trim(),
                postal_code: postalCode.trim(),
                payment: paymentMethod,
            }, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                }
            }).then(({ data }) => {
                setOrdering(false);
                storage.set(STORE_KEY_ADDRESS, address);
                storage.set(STORE_KEY_POSTAL_CODE, postalCode);
                if (data && data.data && data.data.redirect) {
                    window.location.href = data.data.redirect
                } else {
                    toast({
                        color: 'success',
                        heading: 'Thanks!',
                        message: 'Your order was placed successfully.',
                    });
                    clear();
                    remove();
                    history.push(`/${store.slug}`)
                }
            }).catch(error => {
                setOrdering(false);
                let message;
                if (error.response && error.response.status === 422) {
                    const { errors } = error.response.data;
                    setErrors({
                        address: errors.address && errors.address[0],
                        postal_code: errors.postal_code && errors.postal_code[0],
                    });
                    const messages = Object.values(errors);
                    message = messages[0][0];
                }

                toast({
                    color: 'danger',
                    heading: 'Error!',
                    message: message || 'There was an error in placing your order. Please try again.',
                });
            })
        } else {
            setOrderAutomatically(true);
            login(true);
        }
    };
    useEffect(() => {
        if (orderAutomatically && !!auth?.token) {
            setOrderAutomatically(false);
            handlePlaceOrder();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, orderAutomatically]);
    useEffect(() => {
        if (errors?.address) {
            $refAddress.current?.focus();
        } else if (errors?.postal_code) {
            $refPostalCode.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (
        <div>
            <Helmet>
                <title>Cart | {store.name}</title>
            </Helmet>
            {cart.data.length > 0 ? (
                <div>
                    <ListGroup className="border-bottom mb-3" flush>
                        {cart.data.map(cartItem => (
                            <ListGroupItem className="p-3" key={createItemKey(cartItem)}>
                                <ItemRow currency={store.currency_symbol}
                                         item={cartItem.item}
                                         onDecrement={() => decrement({ item: cartItem.item, options: cartItem.options })}
                                         onIncrement={() => increment({ item: cartItem.item, options: cartItem.options })}
                                         options={cartItem.options}
                                         quantity={cartItem.quantity}
                                         quantityInStock={getItemQuantityFromInventoryWithOptions(cartItem.item, cartItem.options)}
                                         store={store} />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    <div className="bg-white border-top border-bottom p-3 mb-3">
                        {coupon ? (
                            <div>
                                <div className="d-flex justify-content-between">
                                    <strong>Coupon applied</strong>
                                    <span>{coupon.code}</span>
                                </div>
                                <p className="text-right mb-0">
                                    <small>
                                        <Link to={`/${store.slug}/coupons`}>Change</Link>
                                        {' - '}
                                        <a href="/" onClick={e => { e.preventDefault(); remove() }}>Remove</a>
                                    </small>
                                </p>
                            </div>
                        ) : (
                            <Link to={`/${store.slug}/coupons`}>Click to apply coupon.</Link>
                        )}
                    </div>
                    <div className="bg-white border-top border-bottom p-3 mb-3">
                        {service === 'delivery' && (store.delivery_fee > 0 || coupon) ? (
                            <div className="d-flex justify-content-between">
                                <strong>Subtotal</strong>
                                <span>{store.currency_symbol}{subtotal(cart.data)}</span>
                            </div>
                        ) : null}
                        {service === 'delivery' && store.delivery_fee > 0 ? (
                            <div className="d-flex justify-content-between">
                                <strong>Delivery fee</strong>
                                <span>{store.delivery_fee_display}</span>
                            </div>
                        ) : null}
                        {coupon ? (
                            <div className="d-flex justify-content-between">
                                <strong>Discount</strong>
                                <span>{store.currency_symbol}{discount(cart.data, coupon)}</span>
                            </div>
                        ) : null}
                        <div className="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>{store.currency_symbol}{total(cart.data, coupon, store, service)}</span>
                        </div>
                    </div>
                    <div className="bg-white border-top border-bottom p-3 mb-3">
                        <FormGroup>
                            <Label for={`cart-service-${service}`}><strong>Order type</strong></Label>
                            {Object.keys(services).map(key => (
                                    <CustomInput defaultChecked={service === key}
                                                 id={`cart-service-${key}`}
                                                 key={`cart-service-${key}`}
                                                 label={services[key]}
                                                 onChange={e => e.currentTarget.checked && setService(key)}
                                                 name="service"
                                                 type="radio" />
                                )
                            )}
                        </FormGroup>
                        {service === 'delivery' ? (
                            <>
                                <FormGroup>
                                    <Label for="cart-delivery-address"><strong>Delivery address</strong></Label>
                                    <Input id="cart-delivery-address"
                                           innerRef={$refAddress}
                                           invalid={!!errors.address}
                                           onChange={e => setAddress(e.target.value)}
                                           rows={3}
                                           value={address} type="textarea" />
                                    {!!errors.address ? <FormFeedback>{errors.address}</FormFeedback> : null}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="cart-delivery-postal-code"><strong>Postal code</strong></Label>
                                    <Input id="cart-delivery-postal-code"
                                           innerRef={$refPostalCode}
                                           invalid={!!errors.postal_code}
                                           onChange={e => setPostalCode(e.target.value)}
                                           value={postalCode} />
                                    {!!errors.postal_code ? <FormFeedback>{errors.postal_code}</FormFeedback> : null}
                                </FormGroup>
                            </>
                        ) : null}
                        <FormGroup className="mb-0">
                            <Label for={`cart-payment-${paymentMethod}`}><strong>Payment mode</strong></Label>
                            {Object.keys(store.payment_methods).map(key => (
                                    <CustomInput defaultChecked={paymentMethod === key}
                                                 id={`cart-payment-${key}`}
                                                 key={`cart-payment-${key}`}
                                                 label={store.payment_methods[key]}
                                                 onChange={e => e.currentTarget.checked && setPaymentMethod(key)}
                                                 name="payment"
                                                 type="radio" />
                                )
                            )}
                        </FormGroup>
                    </div>
                    {!store.serviceable ? (
                        <div className="bg-white border-top border-bottom p-3 mb-3">
                            <span className="text-danger">This store is currently not accepting orders.</span>
                        </div>
                    ) : null}
                    {store.minimum_total > 0 && subtotal(cart.data) < store.minimum_total ? (
                        <div className="bg-white border-top border-bottom p-3 mb-3">
                            <span className="text-danger">
                                The order total must be at least {store.minimum_total_display}.
                            </span>
                        </div>
                    ) : null}
                    <div className="d-flex">
                        <span className="m-3">&nbsp;</span>
                    </div>
                    <div className="bg-white border-top position-fixed shadow"
                         style={{bottom: 0, left: 0, right: 0, zIndex: 1}}>
                        <div className="d-flex justify-content-between">
                            <span className="m-3">{store.currency_symbol}{total(cart.data, coupon, store, service)}</span>
                            <Button className="rounded-0"
                                    color="success"
                                    disabled={!store.serviceable || (store.minimum_total > 0 && subtotal(cart.data) < store.minimum_total) || ordering}
                                    onClick={() => handlePlaceOrder()}>
                                <FontAwesomeIcon className="mr-1"
                                                 icon={ordering ? faCircleNotch : faCheck}
                                                 spin={ordering} />
                                {' '}
                                {ordering ? 'Ordering…' : 'Place order'}
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="text-center text-muted my-3">
                    <small>There's nothing in your cart. <Link to={`/${store.slug}`}>Let's add some.</Link></small>
                </p>
            )}
        </div>
    )
};

export default connect(state => ({
    auth: state.auth,
    cart: state.cart,
    coupon: state.coupons.coupon,
    hotspot: state.hotspot.data,
    store: state.store.data,
}), {
    login,
    clear,
    decrement,
    increment,
    remove,
    toast,
})(withRouter(CartView));
