import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import ImageOrPlaceholder from '../components/ImageOrPlaceholder';

const CategoryRow = ({ category }) => (
    <Media>
        <div className="align-self-center mr-3">
            <ImageOrPlaceholder alt={category.name} src={category.image} size={48} />
        </div>
        <Media body>
            <h5>{category.name}</h5>
            <p className="mb-0">{category.items} Items</p>
        </Media>
    </Media>
);

CategoryRow.propTypes = {
    category: PropTypes.object.isRequired,
};

export default CategoryRow;
