import { useEffect, useRef } from 'react';

const useDebounce = (callback, delay) => {
    const $ref = useRef(null);

    useEffect(() => {
        // Cleanup the previous timeout on re-render
        return () => {
            if ($ref.current) {
                clearTimeout($ref.current);
            }
        };
    }, []);

    return (...args) => {
        if ($ref.current) {
            clearTimeout($ref.current);
        }

        $ref.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

export default useDebounce;
