import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import MenuButton from './MenuButton';
import { show as toast } from '../store/slices/toast';
import http from '../utility/http';

function getShareUrl(slug, type, id) {
    return http.post(`${type}/${id}/share`, null, { params: { store: slug } })
        .then(({ data: { url } }) => url)
}

function FloatingActions({ cart, store, toast }) {
    const { pathname } = useLocation();
    const params = useParams();
    const [sharing, setSharing] = useState(false);
    if (pathname.endsWith("/cart")) {
        return null;
    }

    const isItemList = pathname.includes('categories')
        && pathname.includes('items')
        && !! params.id;
    const isItemDetail = pathname.includes('categories')
        && pathname.includes('items')
        && !! params.item;

    function handleShareClicked() {
        setSharing(true);
        getShareUrl(store.slug, isItemList ? 'categories' : 'items', isItemList ? params.id : params.item)
            .then((url) => {
                if (!navigator.canShare) {
                    toast({
                        color: 'danger',
                        heading: 'Error!',
                        message: 'Your browser does not support more sharing options.',
                    });
                    return;
                }

                return navigator.share({
                    title: store.name,
                    text: `Click on this link to browse and shop from our website.`,
                    url,
                });
            })
            .catch(() => {
                toast({
                    color: 'danger',
                    heading: 'Error!',
                    message: 'Failed to launch sharing options.',
                });
            })
            .finally(() => setSharing(false));
    }

    return (
        <>
            <div className="position-relative" style={{height: '58px'}} />
            <div className="position-fixed"
                 style={{bottom: '10px', left: 0, right: 0, zIndex: 99}}>
                <ButtonToolbar className="justify-content-center">
                    <MenuButton store={store}/>
                    {cart.length > 0 && (
                        <Button className="rounded-pill shadow ml-1" color="success" tag={Link}
                                to={`/${store.slug}/cart`}>
                            Checkout <FontAwesomeIcon className="ml-1" icon={faArrowRight}/>
                        </Button>
                    )}
                    {(isItemList || isItemDetail) && (
                        <Button className="rounded-pill shadow ml-1"
                                color="dark"
                                disabled={sharing}
                                onClick={handleShareClicked}>
                            <FontAwesomeIcon icon={faShareAlt}/>
                        </Button>
                    )}
                </ButtonToolbar>
            </div>

        </>
    );
}

FloatingActions.propTypes = {
    store: PropTypes.object.isRequired,
}

export default connect(state => ({
    cart: state.cart.data,
    store: state.store.data,
}), {
    toast,
})(FloatingActions);
