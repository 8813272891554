import { createSlice } from '@reduxjs/toolkit';
import store from 'store';

export const slice = createSlice({
    name: 'auth',
    initialState: {
        popup: false,
        token: store.get('token'),
    },
    reducers: {
        login(state, { payload }) {
            store.set('token', payload.token);
            state.token = payload.token;
        },
        logout(state) {
            store.remove('token');
            state.token = null;
        },
        popup(state, { payload }) {
            state.popup = !!payload;
        },
    },
});

export const { login, logout, popup } = slice.actions;

export default slice.reducer;
