import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import HorizontalScrollingMenu from 'react-horizontal-scrolling-menu';
import { FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import BannerSlider from '../components/BannerSlider';
import CategoryCell from '../components/CategoryCell';
import ItemCell from '../components/ItemCell';
import ItemOptionSheet from "../components/ItemOptionSheet";
import LoadingText from '../components/LoadingText';
import { decrement, increment } from '../store/slices/cart';
import { getItemQuantityFromCart, isItemInStock } from '../utility/cart';
import http from '../utility/http';

class HomeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            banners: {
                data: [],
                loading: true,
            },
            categories: {
                data: [],
                loading: true,
            },
            items: {
                data: [],
                loading: true,
            },
            options: {
                item: null,
                open: false,
            },
            saleItems: {
                data: [],
                loading: true,
            },
        }
    }

    componentDidMount() {
        this.reloadBanners();
        this.reloadCategories();
        this.reloadSaleItems();
        this.reloadItems()
    }

    handleDecrement(item, options) {
        const { decrement } = this.props;
        decrement({ item, options });
        this.handleOptionsHide()
    }

    handleIncrement(item, options) {
        const { increment } = this.props;
        increment({ item, options });
        this.handleOptionsHide()
    }

    handleOptionsHide() {
        this.setState({
            options: {
                item: null,
                open: false,
            }
        })
    }

    handleOptionsShow(item) {
        this.setState({
            options: {
                item,
                open: true,
            }
        })
    }

    reloadBanners() {
        this.setState({
            banners: {
                data: [],
                loading: true,
            },
        });
        const { store: { slug } } = this.props;
        http.get('banners', { params: { store: slug } })
            .then(({ data: { data } }) => {
                this.setState({
                    banners: { data, loading: false },
                })
            })
            .catch(() => {
                this.setState({
                    banners: {
                        data: [],
                        loading: false,
                    },
                })
            })
    }

    reloadCategories() {
        this.setState({
            categories: {
                data: [],
                loading: true,
            },
        });
        const { store: { slug } } = this.props;
        http.get('categories', { params: { store: slug, featured: 1 } })
            .then(({ data: { data } }) => {
                this.setState({
                    categories: { data, loading: false },
                })
            })
            .catch(() => {
                this.setState({
                    categories: {
                        data: [],
                        loading: false,
                    },
                })
            })
    }

    reloadItems() {
        this.setState({
            items: {
                data: [],
                loading: true,
            },
        });
        const { store: { slug } } = this.props;
        http.get('items', { params: { store: slug, featured: 1 } })
            .then(({ data: { data } }) => {
                this.setState({
                    items: { data, loading: false },
                })
            })
            .catch(() => {
                this.setState({
                    items: {
                        data: [],
                        loading: false,
                    },
                })
            })
    }

    reloadSaleItems() {
        this.setState({
            saleItems: {
                data: [],
                loading: true,
            },
        });
        const { store: { slug } } = this.props;
        http.get('items', { params: { store: slug, sale: 1 } })
            .then(({ data: { data } }) => {
                this.setState({
                    saleItems: { data, loading: false },
                })
            })
            .catch(() => {
                this.setState({
                    saleItems: {
                        data: [],
                        loading: false,
                    },
                })
            })
    }

    render() {
        const { cart, history, store } = this.props;
        const { banners, categories, items, options, saleItems } = this.state;
        return (
            <div>
                {banners.loading ? (
                    <p className="text-center my-3">
                        <LoadingText />
                    </p>
                ) : (
                    banners.data.length > 0 ? (
                        <div className="my-3">
                            <BannerSlider banners={banners.data} />
                        </div>
                    ) : (
                        <h1 className="h2 text-center m-3">
                            <small>welcome to</small>
                            <br />
                            <span className="font-weight-bold text-uppercase">{store.name}</span>
                        </h1>
                    )
                )}
                <FormGroup className="m-3">
                    <Input bsSize="lg"
                           onFocus={() => history.push(`/${store.slug}/search?autofocus=1`)}
                           placeholder="Search for items…"
                           type="search" />
                </FormGroup>
                <div className="d-flex justify-content-between mx-3 mb-3">
                    <h6 className="mb-0">Featured categories</h6>
                    <small className="align-self-center">
                        <Link className="text-body" to={`/${store.slug}/categories`}>See all</Link>
                        {' '}
                        <FontAwesomeIcon icon={faArrowRight} />
                    </small>
                </div>
                {categories.loading ? (
                    <p className="text-center">
                        <LoadingText />
                    </p>
                ) : (
                    categories.data.length > 0 ? (
                        <div className="mb-3">
                            <HorizontalScrollingMenu
                                alignCenter={false}
                                data={categories.data.map((category, i) => (
                                    <div className={i === 0 ? 'mx-3' : 'mr-3'}
                                         key={`category-${category.id}`}
                                         style={{width: '150px'}}>
                                        <CategoryCell category={category} className="shadow-sm border-0" store={store} />
                                    </div>
                                ))}
                                hideArrows
                                wrapperStyle={{padding: '2px 0 4px 0'}} />
                        </div>
                    ) : (
                        <p className="text-center text-muted mx-3 mb-3">
                            <small>No featured categories to show here.</small>
                        </p>
                    )
                )}
                <div className="d-flex justify-content-between mx-3 mb-3">
                    <h6 className="mb-0">Featured items</h6>
                    <small className="align-self-center">
                        <Link className="text-body" to={`/${store.slug}/search?featured=1`}>See all</Link>
                        {' '}
                        <FontAwesomeIcon icon={faArrowRight} />
                    </small>
                </div>
                {items.loading ? (
                    <p className="text-center my-3">
                        <LoadingText />
                    </p>
                ) : (
                    items.data.length > 0 ? (
                        <div className="mb-3">
                            <HorizontalScrollingMenu
                                alignCenter={false}
                                data={items.data.map((item, i) => (
                                    <div className={i === 0 ? 'mx-3' : 'mr-3'}
                                         key={`item-${item.id}`}
                                         style={{width: '150px'}}>
                                        <ItemCell className="shadow-sm border-0"
                                                  inStock={isItemInStock(item)}
                                                  item={item}
                                                  quantity={getItemQuantityFromCart({ items: cart }, item)}
                                                  store={store}
                                                  onDecrement={() => this.handleDecrement(item)}
                                                  onIncrement={() => {
                                                      item.options.length
                                                          ? this.handleOptionsShow(item)
                                                          : this.handleIncrement(item)
                                                  }} />
                                    </div>
                                ))}
                                hideArrows
                                wrapperStyle={{padding: '2px 0 4px 0'}} />
                        </div>
                    ) : (
                        <p className="text-center text-muted mx-3 mb-3">
                            <small>No featured items to show here.</small>
                        </p>
                    )
                )}
                {saleItems.loading ? (
                    <p className="text-center my-3">
                        <LoadingText />
                    </p>
                ) : (
                    saleItems.data.length > 0 ? (
                        <>
                            <div className="d-flex justify-content-between mx-3 mb-3">
                                <h6 className="mb-0">Items on sale</h6>
                                <small className="align-self-center">
                                    <Link className="text-body" to={`/${store.slug}/search?sale=1`}>See all</Link>
                                    {' '}
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </small>
                            </div>
                            <div className="mb-3">
                                <HorizontalScrollingMenu
                                    alignCenter={false}
                                    data={saleItems.data.map((item, i) => (
                                        <div className={i === 0 ? 'mx-3' : 'mr-3'}
                                             key={`sale-item-${item.id}`}
                                             style={{width: '150px'}}>
                                            <ItemCell className="shadow-sm border-0"
                                                      inStock={isItemInStock(item)}
                                                      item={item}
                                                      quantity={getItemQuantityFromCart({items: cart}, item)}
                                                      store={store}
                                                      onDecrement={() => this.handleDecrement(item)}
                                                      onIncrement={() => {
                                                          item.options.length
                                                              ? this.handleOptionsShow(item)
                                                              : this.handleIncrement(item)
                                                      }}/>
                                        </div>
                                    ))}
                                    hideArrows
                                    wrapperStyle={{padding: '2px 0 4px 0'}}/>
                            </div>
                        </>
                    ) : null
                )}
                <ItemOptionSheet cart={cart}
                                 currency={store.currency_symbol}
                                 onCommit={selection => this.handleIncrement(options.item, selection)}
                                 onDismiss={() => this.handleOptionsHide()}
                                 item={options.item}
                                 open={options.open}/>
            </div>
        )
    }
}

export default connect(state => ({
    cart: state.cart.data,
    store: state.store.data,
}), {
    decrement,
    increment,
})(withRouter(HomeView));
