import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ImageOrPlaceholderFluid } from '../components/ImageOrPlaceholder';
import ItemOptionSheet from '../components/ItemOptionSheet';
import LoadingText from '../components/LoadingText';
import { decrement, increment } from '../store/slices/cart';
import http from '../utility/http';
import { getItemQuantityFromCart, isItemInStock } from '../utility/cart';

const Loader = () => (
    <p className="text-center mt-3 mb-0">
        <LoadingText />
    </p>
);

class ItemDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            loading: false,
            options: false,
        };
    }

    componentDidMount() {
        const { item } = this.props.match.params;
        const { store } = this.props;
        this.setState({ loading: true })
        http.get('items/' + item, { params: { store: store.slug } })
            .then(({ data: { data } }) => {
                this.setState(state => ({
                    item: data,
                    loading: false,
                }))
            })
            .catch(() => this.setState({ loading: false }))
    }

    handleIncrement(options) {
        const { increment } = this.props;
        const { item } = this.state;
        increment({ item, options });
        this.handleOptionsHide();
    }

    handleOptionsHide() {
        this.setState({ options: false });
    }

    handleOptionsShow() {
        this.setState({ options: true });
    }

    render() {
        const { item, loading, options } = this.state;
        const { cart, store } = this.props;

        const onIncrement = () => {
            if (item.options.length) {
                this.handleOptionsShow();
            } else {
                this.handleIncrement([]);
            }
        };

        const inStock = item ? isItemInStock(item) : false;
        const quantity = item ? getItemQuantityFromCart({ items: cart }, item) : 0;

        return (
            <div>
                <Helmet>
                    {item && <title>{item.name} | Items | {store.name}</title>}
                </Helmet>
                {item && !loading ? (
                    <>
                        <div className="d-flex flex-column">
                            <div className="bg-white border-top border-bottom mb-3">
                                {item.veg === 'veg' ? (
                                    <div className="veg-badge">
                                        <div className="veg-box veg-box-v">
                                            <div className="veg-dot veg-dot-v" />
                                        </div>
                                    </div>
                                ) : item.veg === 'non-veg' ? (
                                    <div className="veg-badge">
                                        <div className="veg-box veg-box-nv">
                                            <div className="veg-dot veg-dot-nv" />
                                        </div>
                                    </div>
                                ) : null}
                                <ImageOrPlaceholderFluid alt={item.name} src={item.image} />
                            </div>
                            <div className="bg-white border-top border-bottom p-3 mb-3">
                                <h1 className="h4 mb-1">{item.name}</h1>
                                <p className="mb-1">
                                    <Link to={`/${store.slug}/categories/${item.category.id}/items`}>
                                        See all in {item.category.name} category
                                    </Link>
                                    <FontAwesomeIcon className="text-primary ml-1" icon={faAngleRight} />
                                </p>
                                <p className={store.serviceable && inStock ? 'mb-2' : 'mb-0'}>
                                    <strong>{item.price_display}</strong>
                                    {item.compare_price && (<del className="text-muted ml-1">{item.compare_price_display}</del>)}
                                </p>
                                {store.serviceable && (
                                    <>
                                        {inStock ? quantity > 0 ? (
                                            <Button color="success" onClick={onIncrement} outline>
                                                <FontAwesomeIcon icon={faPlus} /> Buy more
                                            </Button>
                                        ) : (
                                            <Button color="success" onClick={onIncrement} outline>
                                                <FontAwesomeIcon icon={faPlus} /> Buy
                                            </Button>
                                        ) : (
                                            <Button className="px-0 border-0" color="danger" disabled outline size="sm">
                                                Out of Stock
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="bg-white border-top border-bottom p-3 mb-3">
                                <h4 className="mb-1">Information</h4>
                                {item.description ? (
                                    <p className="mb-0">{item.description}</p>
                                ) : (
                                    <p className="text-muted mb-0">No description added.</p>
                                )}
                            </div>
                            <div className="bg-white border-top border-bottom p-3 mb-3">
                                <h4 className="mb-1">Specifications</h4>
                                {item.specifications.length ? (
                                    <ListGroup className="mb-0" flush>
                                        {item.specifications.map(({name, value}, i) => (
                                            <ListGroupItem className="px-0 py-1" key={'item-specification-' + i}>
                                                <strong>{name}:</strong> {value}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                ) : (
                                    <p className="text-muted mb-0">
                                        {item.description || 'No specifications added.'}
                                    </p>
                                )}
                            </div>
                        </div>
                        <ItemOptionSheet cart={cart}
                                         currency={store.currency_symbol}
                                         onCommit={selection => this.handleIncrement(selection)}
                                         onDismiss={() => this.handleOptionsHide()}
                                         item={item}
                                         open={options} />
                    </>
                ) : (
                    <Loader/>
                )}
            </div>
        );
    }
}

export default connect(state => ({
    cart: state.cart.data,
    store: state.store.data,
}), {
    decrement,
    increment,
})(withRouter(ItemDetailView));
