import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MenuButtonStyled = styled.a`
  background: ${({ colors }) => colors.secondary.bg};
  color: ${({ colors }) => colors.secondary.fg};
  &:active, &:focus, &:hover {
    background: ${({ colors }) => darken(0.1, colors.secondary.bg)};
    color: ${({ colors }) => transparentize(0.25, colors.secondary.fg)};
    text-decoration: none;
  }
`;

const MenuButton = ({ store: { slug, colors } }) => {
    return (
        <MenuButtonStyled as={Link} className="btn rounded-pill shadow" colors={colors} to={`/${slug}/categories`}>
            <FontAwesomeIcon className="mr-1" icon={faBars} /> Menu
        </MenuButtonStyled>
    )
};

MenuButton.propTypes = {
    store: PropTypes.object.isRequired,
};

export default MenuButton;
