import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import ImageOrPlaceholder from './ImageOrPlaceholder';
import LocalTime from './LocalTime';
import { orderStatusName } from '../utility/fixtures';

const count = (items) => {
    let count = 0;
    items.map(x => count += x.quantity);
    return count
}

const OrderRow = ({ order }) => {
    const images = order.items.filter(orderItem => orderItem.item.image != null)
        .map(orderItem => orderItem.item.image);
    return (
        <Media>
            <div className="align-self-center mr-3">
                <ImageOrPlaceholder alt={order.uid} src={images[0]} size={48} />
            </div>
            <Media body>
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1"><strong>#{order.uid}</strong></h5>
                    <small><LocalTime value={order.created_at} /></small>
                </div>
                <p className="mb-1">You ordered {count(order.items)} item(s).</p>
                <small>
                    <strong>Total:</strong> {order.total_display} &bull; <strong>Status:</strong> {order.payment ? orderStatusName(order.status) : 'Unpaid'}
                </small>
            </Media>
        </Media>
    )
};

OrderRow.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderRow;
