import React from 'react';
import PropTypes from 'prop-types';

const ImageOrPlaceholder = ({ alt, size, src }) => {
    if (src) {
        return <img alt={alt} className="rounded" height={size} src={src} />
    }

    return (
        <svg className="rounded" width={size} height={size}
             xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="#eeeeee" />
        </svg>
    )
};

ImageOrPlaceholder.propTypes = {
    alt: PropTypes.string,
    size: PropTypes.number.isRequired,
    src: PropTypes.string,
};

const ImageOrPlaceholderResponsive = ({ alt, src }) => {
    if (src) {
        return <img alt={alt} className="rounded w-100 mw-100" src={src} />
    }

    return (
        <svg className="rounded w-100 mw-100" height={250}
             xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="#eeeeee" />
        </svg>
    )
};

ImageOrPlaceholderResponsive.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
};

export const ImageOrPlaceholderFluid = ImageOrPlaceholderResponsive;

export default ImageOrPlaceholder;
