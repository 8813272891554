import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SaleBadgeStyled = styled.span`
    background: ${({ colors }) => colors.secondary.bg};
    color: ${({ colors }) => colors.secondary.fg};
    left: .5rem;
    position: absolute;
    top: .5rem;
`;

const SaleBadge = ({ store: { colors } }) => {
    return (
        <SaleBadgeStyled className="badge" colors={colors}>Sale</SaleBadgeStyled>
    )
};

SaleBadge.propTypes = {
    store: PropTypes.object.isRequired,
};

export default SaleBadge;
