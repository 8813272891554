import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const QuantityPlusMinus = ({ max, min, onDecrement, onIncrement, size, value }) => (
    <InputGroup className="w-auto">
        <InputGroupAddon addonType="prepend">
            <Button color="link"
                    disabled={typeof min !== 'undefined' && value <= min}
                    onClick={onDecrement}
                    size={size}>
                <FontAwesomeIcon icon={faMinus} />
            </Button>
        </InputGroupAddon>
        <Input bsSize={size}
               max={max || undefined}
               min={min || undefined}
               onChange={() => {}}
               style={{width: '64px', textAlign: 'center'}}
               value={value} />
        <InputGroupAddon addonType="append">
            <Button color="link"
                    disabled={typeof max !== 'undefined' && value >= max}
                    onClick={onIncrement}
                    size={size}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </InputGroupAddon>
    </InputGroup>
);

QuantityPlusMinus.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['sm', 'lg']),
    value: PropTypes.number.isRequired,
};

export default QuantityPlusMinus;
