import {
    faBan,
    faBiking,
    faBoxOpen,
    faClipboardCheck,
    faConciergeBell,
    faDrumstickBite,
    faClock,
    faShoppingBag
} from '@fortawesome/free-solid-svg-icons';

export const orderStatusColor = status => {
    switch (status) {
        case 'accepted':
            return 'warning';
        case 'cancelled':
            return 'danger';
        case 'delivered':
        case 'served':
            return 'success';
        case 'dispatched':
        case 'ready':
            return 'info';
        default:
            return 'muted';
    }
}

export const orderStatusIcon = (status, type) => {
    switch (status) {
        case 'accepted':
            return faClipboardCheck;
        case 'cancelled':
            return faBan;
        case 'delivered':
            return faBoxOpen;
        case 'dispatched':
            return faBiking;
        case 'ready':
            return type === 'dine-in' ? faConciergeBell : faShoppingBag;
        case 'served':
            return faDrumstickBite;
        default:
            return faClock;
    }
}

export const orderStatusName = status => {
    switch (status) {
        case 'accepted':
            return 'Accepted';
        case 'cancelled':
            return 'Cancelled';
        case 'delivered':
            return 'Delivered';
        case 'dispatched':
            return 'Dispatched';
        case 'ready':
            return 'Ready';
        case 'served':
            return 'Served';
        default:
            return 'Pending';
    }
}
