import React from 'react';
import { connect } from 'react-redux';
import ToastMessage from './ToastMessage';

const Toast = ({ toasts }) => (
    <div className="position-fixed" style={{bottom: '10px', left: '10px', right: '10px', zIndex: 9999}}>
        {toasts.map(toast => <ToastMessage key={`toast-${toast.id}`} toast={toast} />)}
    </div>
);

export default connect(state => ({ toasts: state.toast.data }))(Toast)
