import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import ImageOrPlaceholder from './ImageOrPlaceholder';
import SaleBadge from './SaleBadge';

const ItemCell = ({ inStock, item, onDecrement, onIncrement, quantity, quantityInStock, store: { colors, serviceable, slug }, ...props }) => (
    <Card {...props}>
        <CardBody className="p-2">
            {item.veg === 'veg' ? (
                <div className="veg-badge">
                    <div className="veg-box veg-box-v">
                        <div className="veg-dot veg-dot-v" />
                    </div>
                </div>
            ) : item.veg === 'non-veg' ? (
                <div className="veg-badge">
                    <div className="veg-box veg-box-nv">
                        <div className="veg-dot veg-dot-nv" />
                    </div>
                </div>
            ) : null}
            {item.compare_price && (<SaleBadge store={{ colors }} />)}
            <div className="d-flex justify-content-center mb-3">
                <Link to={`/${slug}/categories/${item.category.id}/items/${item.id}`}>
                    <ImageOrPlaceholder alt={item.name} size={100} src={item.image} />
                </Link>
            </div>
            <CardText className="mb-1">
                <Link className="text-body"
                      to={`/${slug}/categories/${item.category.id}/items/${item.id}`}>
                    {item.name}
                </Link>
            </CardText>
            <CardText><strong>{item.price_display}</strong></CardText>
            {serviceable && (
                <ButtonToolbar>
                    {inStock ? quantity > 0 ? (
                        <Button className="mx-auto" color="success" onClick={onIncrement} outline size="sm">
                            <FontAwesomeIcon icon={faPlus} /> Buy more
                        </Button>
                    ) : (
                        <Button className="mx-auto" color="success" onClick={onIncrement} outline size="sm">
                            <FontAwesomeIcon icon={faPlus} /> Buy
                        </Button>
                    ) : (
                        <Button className="mx-auto border-0" color="danger" disabled outline size="sm">
                            Out of Stock
                        </Button>
                    )}
                </ButtonToolbar>
            )}
        </CardBody>
    </Card>
)

ItemCell.propTypes = {
    inStock: PropTypes.bool,
    item: PropTypes.object.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    quantityInStock: PropTypes.number,
    store: PropTypes.object.isRequired,
};

export default ItemCell;
