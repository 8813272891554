import randomstring from 'randomstring';
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'toast',
    initialState: {
        data: [],
    },
    reducers: {
        hide(state, { payload }) {
            state.data = state.data.filter(m => m.id !== payload)
        },
        show(state, { payload }) {
            if (typeof payload === 'string') {
                payload = { message: payload }
            }

            payload.id = randomstring.generate();
            state.data.push(payload)
        },
    },
});

export const { hide, show } = slice.actions;

export default slice.reducer;
